<template>
  <div class="divide-y rounded-md shadow-lg divide-neutral-600">
    <ul v-if="canPerformNonDestructiveAction" class="p-1">
      <li v-tooltip="lockedTooltip">
        <button
          :disabled="isLocked"
          v-close-popover
          v-if="canRename"
          @click.prevent="
            $emit('rename-asset')
            close()
          "
          class="flex items-center disabled:opacity-50 w-full px-4 py-2 text-sm border border-transparent rounded-md group text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
        >
          <fa-icon
            class="mr-3 fa-fw text-neutral-100 group-hover:text-neutral-100"
            icon="pencil"
          />
          Rename
        </button>
      </li>
    </ul>
    <ul class="p-1" v-if="asset.cannonical_urls.drive_url">
      <a
        :href="asset.cannonical_urls.drive_url"
        v-close-popover
        class="flex items-center w-full px-4 py-2 text-sm border border-transparent rounded-md group text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
      >
        <fa-icon
          class="mr-3 fa-fw text-neutral-100 group-hover:text-neutral-100"
          icon="arrow-up-right-from-square"
        />
        View in Drive
      </a>
    </ul>
    <ul class="p-1" v-if="canDelete">
      <li v-tooltip="lockedTooltip">
        <button
          :disabled="isLocked"
          v-if="canRename"
          @click.prevent="
            $emit('delete-asset')
            close()
          "
          v-close-popper
          class="flex items-center w-full px-4 py-2 disabled:opacity-50 text-sm border border-transparent rounded-md text-neutral-100 hover:bg-red-600 hover:border-red-400 group"
        >
          <fa-icon
            class="mr-3 text-red-600 fa-fw group-hover:text-neutral-100"
            icon="trash-can"
          />
          Delete
        </button>
      </li>
    </ul>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'AssetMenu',

  emits: ['close-popover', 'rename-asset'],

  props: {
    canRename: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    asset: {
      type: Object
    }
  },

  computed: {
    canPerformNonDestructiveAction() {
      return this.canRename
    },
    isLocked() {
      return this.asset.locked
    },
    lockedTooltip() {
      return this.isLocked ? 'Unlock asset to make changes' : ''
    }
  },
  methods: {
    close() {
      this.left = 0
      this.top = 0
      this.$emit('close-popover')
    }
  }
}
</script>
