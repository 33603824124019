import { render, staticRenderFns } from "./asset-item-view-options.vue?vue&type=template&id=452173e1&"
import script from "./asset-item-view-options.vue?vue&type=script&lang=js&"
export * from "./asset-item-view-options.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports