<template>
  <VDropdown>
    <button
      @click.prevent
      type="button"
      class="inline-flex items-center p-1 border border-transparent rounded-md hover:bg-neutral-600 group focus:border-neutral-600"
    >
      <fa-icon
        icon="ellipsis"
        class="text-sm text-gray-200 group-hover:text-neutral-100 fa-fw"
      />
    </button>
    <template #popper="{ hide }">
      <AssetMenu
        v-bind:asset="asset"
        :can-rename="true"
        :can-delete="canDelete"
        @rename-asset="$emit('rename-asset')"
        @delete-asset="$emit('delete-asset')"
        @close-popover="hide()">
        <slot/>
      </AssetMenu>
    </template>
  </VDropdown>
</template>

<script>
import AssetMenu from '@components/assets/assets-explorer/asset-menu.vue'

export default {
  name: 'asset-item-view-options',
  components: {
    AssetMenu
  },
  props: {
    asset: Object,
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['rename-asset', 'delete-asset']
}
</script>
